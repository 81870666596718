import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import React from "react";
import Newssubmenu from "./Newssubmenu";
import { useTranslation } from "react-i18next";

import XTechArticleImg from "../../images/xtech_article.png";
import OISTArticleImg from "../../images/oist_article.png";

const Media = () => {
  const { t } = useTranslation();

  const contents = [
    {
      title: t("media:media1.title"),
      link: "https://xtech.mec.co.jp/articles/9779",
      img: XTechArticleImg,
      description: t("media.media1.description"),
    },
    {
      title: t("media:media2.title"),
      link: "https://www.oist.jp/news-center/news/2023/12/20/dawn-new-era-drones",
      img: OISTArticleImg,
      description: t("media.media2.description"),
    },
  ];

  return (
    <>
      {/* <Newssubmenu /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {contents.map((content) => {
          return (
            <Card
              style={{
                margin: "10px",
                width: "200px",
                // minHeight: '300px',
                borderRadius: "15px",
                cursor: "pointer",
              }}
              onClick={(e) => window.open(content.link, "_blank")}
            >
              <CardMedia
                style={{
                  width: "200px",
                  height: "130px",
                  objectFit: "fill",
                }}
                image={content.img}
              />

              <CardContent>
                <Typography variant="h5" component="h2" align="center">
                  {content.title}
                </Typography>

                <Typography variant="caption" color="textSecondary">
                  {content.description}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default Media;
