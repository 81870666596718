import React, { useState } from 'react';
// import { Card } from 'react-bootstrap';
import NewsCard from './NewsCard';
import { NavLink } from 'react-router-dom';
import Youtube from './News/Youtube.jsx';
import Twitter from './News/Twitter.jsx';
import Linkedin from './News/LinkedIn.jsx';
import Facebook from './News/Facebook.jsx';
import Instagram from './News/Instagram.jsx';

const news = [
  {
    img: 'https://th.bing.com/th/id/OIP.pLdhoJ1d2z_MKT1XmNl8AQHaEK?w=281&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7',
    title: 'Card Title',
    content:
      'Some quick example text to build on the card title and make up the bulk of the cards content.',
  },

  {
    img: 'https://th.bing.com/th/id/OIP.pLdhoJ1d2z_MKT1XmNl8AQHaEK?w=281&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7',
    title: 'Prem Harshe',
    content:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur, quod.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur, quod.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur, quod.',
  },
];

function News() {
  return (
    <>
      <Facebook />
      <Instagram />
      <Linkedin />
      <Twitter />
      <Youtube />

      {/* <div style={{
                display:'flex', 
                flexWrap:'wrap',
                alignItems: 'center',
                justifyContent:'center'}}>

                    {
                        news.map((ele,ind)=>{
                            return(
                                <NewsCard news={ele}></NewsCard>
                            )
                        })
                    }
                
            </div> */}
    </>
  );
}

export default News;
