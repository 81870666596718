import React, { useEffect, Suspense, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Menu from "./components/Menu.jsx";
import WhyVyorius from "./components/whyVyorius";
import Main from "./components/main";
import Page20 from "./components/page20";
import Page21 from "./components/page21";
import Page22 from "./components/page22";
import Page23 from "./components/page23";
import Page25 from "./components/page25";
import Page26 from "./components/page26";
import Footer from "./components/footer";
import Partners from "./components/partners";
import News from "./components/News";
import Team from "./components/team";
import Contact from "./components/contact";
import Page6 from "./components/page6";
import Page7 from "./components/page7";
import Page8 from "./components/page8";
import Page9 from "./components/page9";
import Page10 from "./components/page10";
import Page11 from "./components/page11";
import Page12 from "./components/page12";
import Page13 from "./components/page13";
import Page14 from "./components/page14";
import Page15 from "./components/page15";
import Page16 from "./components/page16";
import Page17 from "./components/page17";
import Page18 from "./components/page18";
import Page19 from "./components/page19";
import Youtube from "./components/News/Youtube.jsx";
import Twitter from "./components/News/Twitter.jsx";
import Linkedin from "./components/News/LinkedIn.jsx";
import Facebook from "./components/News/Facebook.jsx";
import Instagram from "./components/News/Instagram.jsx";
import Media from "./components/News/Newssubmenu.jsx";
import Page27 from "./components/page27.jsx";
import Page28 from "./components/page28.jsx";
import Page29 from "./components/page29.jsx";
import Page30 from "./components/page30.jsx";
import Page31 from "./components/page31.jsx";

import i18n from "./i18n";
import LocaleContext from "./LocaleContext.js";

function App() {
  const [locale, setLocale] = useState(i18n.language);

  i18n.on("languageChanged", () => {
    setLocale(i18n.language);
  });

  return (
    <>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Suspense fallback="loading...">
          <Menu />
          <div className="App">
            <Router>
              <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/whyVyorius" exact component={WhyVyorius} />

                <Route path="/solution/dataSync" exact component={Page6} />
                <Route
                  path="/solution/dataSync/fleetManagement"
                  exact
                  component={Page7}
                />
                <Route
                  path="/solution/dataSync/maintainance"
                  exact
                  component={Page8}
                />
                <Route
                  path="/solution/dataSync/dataAnalytics"
                  exact
                  component={Page9}
                />
                <Route
                  path="/solution/dataSync/dataSharing"
                  exact
                  component={Page10}
                />
                <Route
                  path="/solution/dataSync/intelligence"
                  exact
                  component={Page11}
                />
                <Route
                  path="/solution/dataSync/clientDiscovery"
                  exact
                  component={Page12}
                />
                <Route path="/solution" component={Page6} />

                <Route path="/product/intro" exact component={Page13} />
                <Route
                  path="/product/intro/vyoriusGroundStation"
                  exact
                  component={Page14}
                />
                <Route
                  path="/product/intro/vyoriusOperation"
                  exact
                  component={Page15}
                />
                <Route
                  path="/product/intro/vyoriusMaintainance"
                  exact
                  component={Page16}
                />
                <Route
                  path="/product/intro/vyoriusClient"
                  exact
                  component={Page17}
                />
                {/**<Route
              path="/product/intro/vyoriusAnalytics"
              exact
              component={Page18}
            />**/}
                <Route
                  path="/product/intro/aerialDelivery"
                  exact
                  component={Page19}
                />
                <Route
                  path="/product/intro/vyoriusSimulator"
                  exact
                  component={Page27}
                />
                <Route
                  path="/product/intro/vyoriusPrecisionLanding"
                  exact
                  component={Page28}
                />
                <Route
                  path="/product/intro/vyoriusAIEnabledVideo"
                  exact
                  component={Page29}
                />
                <Route
                  path="/product/intro/vyoriusLiveStreaming"
                  exact
                  component={Page30}
                />
                {/* <Route
              path='/product/intro/vyoriusIndoorNavigation'
              exact
              component={Page31}
            /> */}
                <Route path="/product" component={Page13} />

                <Route
                  path="/useCases/energy/infrastructure"
                  exact
                  component={Page21}
                />
                <Route path="/useCases/energy" exact component={Page23} />
                <Route
                  path="/useCases/energy/publicSafety"
                  exact
                  component={Page22}
                />
                <Route
                  path="/useCases/energy/cargoLogistic"
                  exact
                  component={Page20}
                />
                <Route
                  path="/useCases/energy/miningQuarrying"
                  exact
                  component={Page25}
                />
                <Route path="/useCases/energy/other" exact component={Page26} />
                <Route path="/useCases" component={Page23} />

                <Route path="/OurPartners" exact component={Partners} />
                {/* <Route path="/news/youtube" exact component={Youtube} />
                <Route path="/news/twitter" exact component={Twitter} />
                <Route path="/news/linkedin" exact component={Linkedin} />
                <Route path="/news/facebook" exact component={Facebook} />
                <Route path="/news/instagram" exact component={Instagram} /> */}
                <Route path="/news" exact component={Media} />
                <Route path="/team" exact component={Team} />
                <Route path="/contact" exact component={Contact} />
              </Switch>
              <Footer />
            </Router>
          </div>
        </Suspense>
      </LocaleContext.Provider>
    </>
  );
}

export default App;
