import React from "react";
// import {Image} from 'cloudinary'

// import p27i1 from '../images/brinc.jpeg';
// import p27i2 from '../images/p27i2.jpeg';
// import p27i3 from '../images/p27i3.png';
// import p27i4 from '../images/p27i4.jpeg';
// import p27i5 from '../images/p27i5.jpeg';
// import p27i8 from '../images/p27i8.png';
// import p27i9 from '../images/p27i9.jpeg';
// import p27i10 from '../images/p27i10.png';
// import p27i12 from '../images/p27i12.png';
// import p27i13 from '../images/p27i13.jpeg';
// import p27i14 from '../images/p27i14.png';
// import p27i15 from '../images/p27i15.png';
// import p27i16 from '../images/p27i16.png';
// import p27i17 from '../images/p27i17.png';
// import p27i18 from '../images/p27i18.jpeg';
// import p27i19 from '../images/p27i19.png';
// import p27i20 from '../images/p27i20.png';
// import p27i21 from '../images/sun.png';
// import p27i22 from '../images/p27i22.png';
// import p27i23 from '../images/p27i23.png';
// import p27i24 from '../images/p27i24.png';
// import p27i25 from '../images/p27i25.jpg';
// import p27i27 from '../images/p27i27(3).jpg';
// import p27i28 from '../images/p27i28.jpg';
// import p27i29 from '../images/p27i29.jpg';
// import p27i30 from '../images/p27ic3.jpg';
// import p27i31 from '../images/p27i31.png';

// import p27c1 from '../images/p27c1.jpg';
// import p27c2 from '../images/p27ic2.jpg';
// import p27c3 from '../images/p27ic3.jpg';
// import p27c4 from '../images/p27ic4.jpg';
// import p27c5 from '../images/p27ic5.jpg';
// import p27c6 from '../images/p27ic6.jpg';
// import p27c7 from '../images/p27ic7.jpg';
// import p27c8 from '../images/p27ic8.png';
// import p27c9 from '../images/p27ic9.jpg';

// import image1row2 from '../images/image1row2.jpeg';
// import image2row2 from '../images/image2row2.jpeg';
// import image3row2 from '../images/image3row2.jpeg';
// import image4row2 from '../images/image4row4.jpeg';
// import image5row2 from '../images/imagerrow2.jpeg';

import nokialogo from "../images/Nokia-Logo.png";
import BonVAerologo from "../images/BonV_Aero_logo.png";
import Deparmentoftelecommunicationslogo from "../images/Department_of_Telecommunications_logo.jpg";
import Hatcherlogo from "../images/Hatcher+_logo.png";
import masschallengelogo from "../images/masschallenge_logo.png";
import skytexlogo from "../images/skytex_logo.png";
import oistLogo from "../images/oist_logo.jpg";
import enordLogo from "../images/enord.jpeg";
import aerialIQLogo from "../images/aerialiq_logo.jpeg";
import soaringToSuccessLogo from "../images/soaring_to_success_logo.png";
import fligFlightSchoolLogo from "../images/flig_flight_school_logo.png";
import amosLogo from "../images/amos_logo.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

function Partner() {
  const { t } = useTranslation();

  var settings = {
    className: "sldr",
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,

    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="client-page">
      <div className="client">
        <h1>
          <span className="orangeText">
            {" "}
            {t("partners:clients_and_partners")}
          </span>
        </h1>
        {/* <Slider {...settings}>
          <div class="card-deck">
            <div class="card">
              <div class="card-img">
                <img src={nokialogo} alt="" className="clientimg1" />
              </div>
            </div>
          </div>
          <div class="card-deck">
            <div class="card">
              <div class="card-img">
                <img src={BonVAerologo} alt="" className="clientimg2" />
              </div>
            </div>
          </div>

          <div class="card-deck">
            <div class="card">
              <div class="card-img">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648256/Vyorius%20Website/p27ic7_nbidvu.jpg"
                  alt=""
                  className="clientimg7"
                />
              </div>
              <div class="card-body">
                <h5 class="card-title">Electric Visionary Aircrafts</h5>
                <p class="card-text">
                  Founded by an international team (Japan, France, Italy, India)
                  EVA is a startup at the crossroads of Tesla and Uber which
                  aims to open a third dimension in our cities.
                </p>
              </div>
            </div>
          </div>
          <div class="card-deck">
            <div class="card">
              <div class="card-img">
                <img src={skytexlogo} alt="" className="clientimg7" />
              </div>
              <div class="card-body">
                <h5 class="card-title">Skytex</h5>
                <p class="card-text">
                  Research and Development UAV company which envisions to
                  develop state of the art cutting edge technologies for
                  autonomous/combat UAVs
                </p>
              </div>
            </div>
          </div>

          <div class="card-deck">
            <div class="card">
              <div class="card-img">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648256/Vyorius%20Website/p27ic9_bch88w.webp"
                  alt=""
                  className="clientimg9"
                />
              </div>
              <div class="card-body">
                <h5 class="card-title">F Drones</h5>
                <p class="card-text">
                  F-drones’ vision is a world where maritime logistics is more
                  efficient and sustainable. We provide 24-hr commercial
                  beyond-visual-line-of-sight (BVLOS) drone deliveries.
                </p>
              </div>
            </div>
          </div>
          <div class="card-deck">
            <div class="card">
              <div class="card-img">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1628578914/Vyorius%20Website/Vstream_kd4f4y.png"
                  alt=""
                  className="clientimg10"
                />
              </div>
              <div class="card-body">
                <h5 class="card-title">Vstream</h5>
                <p class="card-text">
                  VStream Revolution Sdn. Bhd. is a company specialised in
                  providing Drone/UAV solution for Industrial & Commercial use.
                  We have distribution ship for the most advance Drone/UAV
                  system and payload that can be used for a variety of
                  application.
                </p>
              </div>
            </div>
          </div>
        </Slider> */}

        <div className="image-flex">
          <div className="imagePadding2">
            <img
              src={
                "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648256/Vyorius%20Website/p27ic9_bch88w.webp"
              }
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
              // className="clientimg"
            />
          </div>

          <div className="imagePadding2">
            <img
              src={BonVAerologo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={skytexlogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={
                "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1628578914/Vyorius%20Website/Vstream_kd4f4y.png"
              }
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={enordLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={aerialIQLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={soaringToSuccessLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
              // className="partnerimg29"
            />
          </div>

          <div className="imagePadding2">
            <img
              src={fligFlightSchoolLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
              // className="partnerimg234"
            />
          </div>
          <div className="imagePadding2">
            <img
              src={amosLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: "100px",
                height: "100px",
                // objectFit: 'contain',
              }}
              // className="partnerimg234"
            />
          </div>

          <div className="image-flex-2">
            {/* <div className="imagePadding2">
            <img
              src={aerialIQLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: '100px',
                height: '100px',
                // objectFit: 'contain',
              }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={soaringToSuccessLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: '100px',
                height: '100px',
                // objectFit: 'contain',
              }}
              // className="partnerimg29"
            />
          </div>

          <div className="imagePadding2">
            <img
              src={fligFlightSchoolLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: '100px',
                height: '100px',
                // objectFit: 'contain',
              }}
              // className="partnerimg234"
            />
          </div>
          <div className="imagePadding2">
            <img
              src={amosLogo}
              alt=""
              style={{
                // border: '1px solid red',
                width: '100px',
                height: '100px',
                // objectFit: 'contain',
              }}
              // className="partnerimg234"
            /> */}
          </div>
          {/* <div className="imagePadding2">
            <img src={masschallengelogo} alt="" className="partnerimg238" />
          </div> */}
        </div>
      </div>
      {/*//client div ends here */}
      <div className="partner">
        <h1>
          <span className="orangeText">{t("partners:supported_by")}</span>
        </h1>

        <div className="image-flex">
          {/* <div className="imagePadding">
            <img src={p27i1} alt="" className="partnerimg1" />
          </div> */}

          <div className="imagePadding2">
            <img
              src={oistLogo}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649585/Vyorius%20Website/p27i2_niljku.jpg"
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          {/* <div className="imagePadding">
            <img src={p27i3} alt="" className="partnerimg234" />
          </div>
          <div className="imagePadding">
            <img src={p27i4} alt="" className="partnerimg234" />
          </div>
          <div className="imagePadding">
            <img src={p27i5} alt="" className="partnerimg5" />
          </div>

          <div className="imagePadding">
            <img src={image1row2} alt="" className="partnerimg7" />
          </div>
          <div className="imagePadding">
            <img src={image2row2} alt="" className="partnerimg7" />
          </div>
          <div className="imagePadding">
            <img src={image3row2} alt="" className="partnerimg7" />
          </div>
          <div className="imagePadding">
            <img src={image4row2} alt="" className="partnerimg7" />
          </div> */}
          {/* <div className="imagePadding">
            <img src={image5row2} alt="" className="partnerimg7" />
          </div> */}

          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649585/Vyorius%20Website/p27i8_yfwa0b.png"
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649585/Vyorius%20Website/p27i9_b2w6rv.jpg"
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          {/* <div className="imagePadding">
            <img src={p27i10} alt="" className="partnerimg9" />
          </div>
          <div className="imagePadding">
            <img src={p27i12} alt="" className="partnerimg10" />
          </div>
          <div className="imagePadding">
            <img src={p27i13} alt="" className="partnerimg11" />
          </div>
          <div className="imagePadding">
            <img src={p27i14} alt="" className="partnerimg12" />
          </div>
          <div className="imagePadding">
            <img src={p27i15} alt="" className="partnerimg13" />
          </div>
          <div className="imagePadding">
            <img src={p27i16} alt="" className="partnerimg14" />
          </div>
          <div className="imagePadding">
            <img src={p27i17} alt="" className="partnerimg15" />
          </div>
          <div className="imagePadding">
            <img src={p27i18} alt="" className="partnerimg16" />
          </div>
          <div className="imagePadding1">
            <img src={p27i19} alt="" className="partnerimg17" />
          </div>
          <div className="imagePadding1">
            <img src={p27i20} alt="" className="partnerimg18" />
          </div>
          <div className="imagePadding1">
            <img src={p27i21} alt="" className="partnerimg19" />
          </div>
          <div className="imagePadding1">
            <img src={p27i22} alt="" className="partnerimg20" />
          </div>
          <div className="imagePadding1">
            <img src={p27i23} alt="" className="partnerimg21" />
          </div> */}
          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625733081/Vyorius%20Website/p27i24_ysmf9x.png"
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          {/* new images added*/}
          {/* <div className="imagePadding2">
            <img src={p27i28} alt="" className="partnerimg26" />
          </div> */}
          {/* <div className="imagePadding2">
            <img src={p27i25} alt="" className="partnerimg23" />
          </div> */}
          {/* <div className="imagePadding2">
            <img src={p27i30} alt="" className="partnerimg28" />
          </div> */}

          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648255/Vyorius%20Website/p27i27_3_dvg3lf.jpg"
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648256/Vyorius%20Website/p27i31_axltq8.png"
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>

          <div className="imagePadding2">
            <img
              src={Deparmentoftelecommunicationslogo}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="imagePadding2">
            <img
              src={Hatcherlogo}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="imagePadding2">
            <img
              src={masschallengelogo}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          {/* <div className="imagePadding2">
            <img src={p27i29} alt="" className="partnerimg27" />
          </div> */}
          {/* <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648256/Vyorius%20Website/p27i31_axltq8.png"
              alt=""
              className="partnerimg29"
            />
          </div> */}
        </div>
        {/* <div className="image-flex-2">
          <div className="imagePadding2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625648256/Vyorius%20Website/p27i31_axltq8.png"
              alt=""
              className="partnerimg29"
            />
          </div>

          <div className="imagePadding2">
            <img
              src={Deparmentoftelecommunicationslogo}
              alt=""
              className="partnerimg234"
            />
          </div>
          <div className="imagePadding2">
            <img src={Hatcherlogo} alt="" className="partnerimg234" />
          </div>
          <div className="imagePadding2">
            <img src={masschallengelogo} alt="" className="partnerimg238" />
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default Partner;
