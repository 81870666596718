import React from "react";

// import p20i1 from "../images/p20i1.png";
import UseCasesSubmenu from "./useCasessubmenu";
import { useTranslation } from "react-i18next";

function Page20() {
  const { t } = useTranslation();

  return (
    <div className="page20 use-case">
      <UseCasesSubmenu />
      <div className="container usecasecontainer">
        <div className="headingcolusecases">
          <h1 className="usecaseh1">
            {" "}
            {t("use_case:page20.connected_robots")}
          </h1>

          <>
            <p className="page20P">
              {" "}
              {t("use_case:page20.inspect_and_maintain")}
              <br />
              {t("use_case:page20.with_vyorius")}
            </p>{" "}
            <ul>
              <li> {t("use_case:page20.solar_panels")} </li>
              <li> {t("use_case:page20.power_plants")} </li>
              <li> {t("use_case:page20.wind_turbines")} </li>
              <li> {t("use_case:page20.transmission")} </li>
              <li> {t("use_case:page20.pipelines")} </li>
            </ul>
          </>
        </div>
        <div className=" usecasecol2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649584/Vyorius%20Website/p20i1_tvdnkt.png"
            alt=""
          />
        </div>
      </div>{" "}
    </div>
  );
}
export default Page20;
