import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const width = window.innerWidth;

  const { t } = useTranslation();

  return (
    <section className="footer" id="footer">
      {/* <a onClick={handleClick}>
        <div className="footer__backToTop">
          Back to Top
          <ExpandLessIcon className="footer__backToTopText" />
        </div>
      </a> */}

      <div className="footer__flex">
        <div className="footer__biglogo">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652819/Vyorius%20Website/27%20may/logo_mm99hq.png"
            }
            alt="Vyorius Logo"
          />
          {width <= 1024 && (
            <span className="footer__lineMessage">
              {t("common:footer.copyright")}
            </span>
          )}
        </div>

        <div className="footer__verticalRow">
          <div className="footer__verticalCol">
            <div className="footer__verticalColHead">
              <a>{t("common:footer.features")}</a>
            </div>
            <ul className="footer__selection">
              <li>
                <a>{t("common:footer.overview")}</a>
              </li>
              <li>
                <a>{t("common:footer.snapshot")}</a>
              </li>
              <li>
                <a>{t("common:footer.recording")}</a>
              </li>
              {/* <li>
                <a>Free trial</a>
              </li>
              <li>
                <a>Pricing</a>
              </li> */}
              <li>
                <a>{t("common:footer.demo_dashboard")}</a>
              </li>
            </ul>
          </div>
          <div className="footer__verticalCol">
            <div className="footer__verticalColHead">
              <a>{t("common:footer.resources")}</a>
            </div>
            <ul className="footer__selection">
              <li>
                <a href="https://www.sente.link/post/creating-a-robotic-ecosystem-how-vyorius-is-changing-robotics">
                  {t("common:footer.blog")}
                </a>
              </li>
              <li>
                <a>{t("common:footer.case_studies")}</a>
              </li>
              <li>
                <a>{t("common:footer.faq")}</a>
              </li>
              <li>
                <a>{t("common:footer.help_center")}</a>
              </li>
              <li>
                <a href="https://goo.gl/maps/ZR5Nk8pfSrCKktWUA">
                  {t("common:footer.site_map")}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__verticalCol">
            <div className="footer__verticalColHead">
              <a>{t("common:footer.legal")}</a>
            </div>
            <ul className="footer__selection">
              <li>
                <a>{t("common:footer.terms_of_use")}</a>
              </li>
              <li>
                <a>{t("common:footer.privacy_policy")}</a>
              </li>
              <li>
                <a>{t("common:footer.cookie_policy")}</a>
              </li>
              <li>
                <a>{t("common:footer.opt_out")}</a>
              </li>
            </ul>
          </div>
          <div className="footer__verticalCol">
            <div className="footer__verticalColHead">
              <a>{t("common:footer.social")}</a>
            </div>
            <ul className="footer__selection">
              <li>
                <a href="https://www.linkedin.com/company/vyorius/">
                  {t("common:footer.linkedin")}
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/Vyorius/">
                  {t("common:footer.facebook")}
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/vyorius/">
                  {t("common:footer.instagram")}
                </a>
              </li>
              <li>
                <a href="https://angel.co/company/vyorius-drones">
                  {t("common:footer.angellist")}
                </a>
              </li>
              <li>
                <a href="https://www.crunchbase.com/organization/vyorius-drones">
                  {t("common:footer.crunchbase")}
                </a>
              </li>
              <li>
                <a href="https://www.f6s.com/vyorius">
                  {t("common:footer.f6s")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {width > 1024 && (
        <span className="footer__lineMessage">
          {`© ${new Date().getFullYear()} ${t("common:footer.copyright")}`}
        </span>
      )}

      {/* <hr color="orange" /> */}

      <div className="footer__line">
        {/* <img className="footer__lineLogo" src={logo} alt="Vyorius Logo" /> */}
        {/* <span className="footer__lineMessage">© 2021 Copyrights: Vyorius</span> */}
      </div>
    </section>
  );
}
export default Footer;
