import React from 'react';
import MainPage from './MainPage';
import Page2 from './page2';


function Main(){
  return(
    <>
    <MainPage />
    <Page2 />
   
    </>);
}
export default Main;
