import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from "../images/ai-video.png";

function Page29() {
  const { t } = useTranslation();

  return (
    <div className="page29 product-page">
      <ProductSubmenu />
      <h1 className="productHeading">
        {" "}
        {t("product:page29.vyorius_ai_enabled_video")}{" "}
      </h1>
      <div className="product-wrapper">
        <div className="product-col2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652810/Vyorius%20Website/27%20may/ai-video_b94uub.png"
            }
            className="productImage"
            alt=""
          />
        </div>{" "}
        <div className="product-col1">
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page29.features")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page29.object_detection_and_counting")}</li>
              <li>{t("product:page29.api_access")}</li>
              <li>{t("product:page29.multi_video_support")}</li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page29.benefits")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page29.hardware_independent")}</li>
              <li>{t("product:page29.optimize_your_battery")}</li>
              <li>{t("product:page29.offboarding_your_ai_needs")}</li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page29.use_cases")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page29.public_safety_and_security")}</li>
              <li>{t("product:page29.rescue_and_disaster_responds")}</li>
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page29.application_areas")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page29.energy")}</li>
              <li>{t("product:page29.construction")}</li>
              <li>{t("product:page29.security")}</li>
              <li>{t("product:page29.smart_cities_and_transportation")}</li>
              <li>{t("product:page29.agriculture_and_healthcare")}</li>
            </ul>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
export default Page29;
