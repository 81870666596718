import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/livestream.png';
// import Img from '../images/live-stream.png';

function Page30() {
  const { t } = useTranslation();

  return (
    <div className="page30 product-page">
      <ProductSubmenu />
      <h1 className="productHeading">
        {" "}
        {t("product:page30.vyorius_live_streaming")}{" "}
      </h1>
      <div className="product-wrapper">
        <div className="product-col2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652818/Vyorius%20Website/27%20may/livestream_waxnux.png"
            }
            className="productImage"
            alt=""
            width="100%"
            height="100%"
          />
        </div>{" "}
        <div className="product-col1">
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page30.live_streaming")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page30.stream_live_video")}</li>
              <li>{t("product:page30.seamless_and_reliable_and_real_time")}</li>
              <li>{t("product:page30.connect_seamlessly")}</li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page30.application_areas")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page30.enhanced_video_footage")}</li>
              <li>{t("product:page30.security_and_monitoring")}</li>
              <li>{t("product:page30.search_and_rescue")}</li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page30.benefits")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page30.faster_response_times")}</li>
              <li>{t("product:page30.safe_and_secure")}</li>
              <li>{t("product:page30.ease_of_sharing")}</li>
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page30.connectivity")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page30.agnostic_of_drone")}</li>
              <li>{t("product:page30.stream_any_video")}</li>
            </ul>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
export default Page30;
