import React from 'react';

import ReactCountryFlag from 'react-country-flag';

import i18n from '../../i18n';

const Language = () => {
  const handleLanguage = (language) => {
    i18n.changeLanguage(language);
    // window.location.hash = `${language}`;
    // window.location.reload();
  };

  return (
    <div style={{ margin: '2px', fontSize: '2em' }}>
      <ReactCountryFlag
        countryCode="US"
        svg
        onClick={(e) => {
          handleLanguage('en');
        }}
        style={{
          cursor: 'pointer',
          // fontSize: '2em',
        }}
      />

      <ReactCountryFlag
        countryCode="JP"
        svg
        onClick={(e) => {
          handleLanguage('ja');
        }}
        style={{
          cursor: 'pointer',
          // fontSize: '2em',
        }}
      />
    </div>
  );
};

export default Language;
