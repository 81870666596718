import React from "react";
import UseCasesSubmenu from "./useCasessubmenu";
import { useTranslation } from "react-i18next";
// import p25i1 from "../images/p25i1.png";
function Page25() {
  const { t } = useTranslation();

  return (
    <div className="page25 use-case">
      <UseCasesSubmenu />
      <div className="container usecasecontainer">
        <div className="headingcolusecases">
          <h1 className="usecaseh1">{t("use_case:page25.boost_efficiency")}</h1>

          <>
            <p className="page20P">
              {t("use_case:page25.robots_can_dramatically")}{" "}
            </p>
            <ul>
              <li> {t("use_case:page25.rapid_and_accurate_serveying")} </li>
              <li> {t("use_case:page25.georeferenced_imagery")}</li>
              <li> {t("use_case:page25.inventory_calculations")}</li>
              <li> {t("use_case:page25.minimal_training_required")} </li>
            </ul>
          </>
        </div>
        <div className="usecasecol2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649585/Vyorius%20Website/p25i1_pcon6g.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
export default Page25;
