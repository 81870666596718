import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/maintenance.png';
// import p16i1 from "../images/maitenance.png";
// import thumbnail from "../images/thumbnail.png";

function Page16() {
  const { t } = useTranslation();

  return (
    <>
      <div className="page16 product-page">
        <ProductSubmenu />
        <h1 className="productHeading">
          {" "}
          {t("product:page16.vyorius_maintenance_manager")}{" "}
        </h1>
        <div className="product-wrapper">
          <div className="product-col2">
            <img
              src={
                "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652819/Vyorius%20Website/27%20may/maintenance_gw9fxz.png"
              }
              className="productImage"
              alt=""
            />
          </div>{" "}
          <div className="product-col1">
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2> {t("product:page16.data_logging")} </h2>{" "}
              </div>
              <ul>
                <li> {t("product:page16.post_mission_logs")} </li>{" "}
                <li> {t("product:page16.efficiency_analysis")} </li>{" "}
                <li> {t("product:page16.asset_dependent_lifecycle")} </li>
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>
                  {" "}
                  {t("product:page16.life_cycle_automation")}
                  {/* <span className="productheadingspan">
                    {' '}
                    {t('product:page16.automation')}
                  </span>{' '} */}
                </h2>{" "}
              </div>
              <ul>
                {" "}
                <li>
                  {" "}
                  {t("product:page16.hardware_software_and_electronics")}{" "}
                </li>{" "}
                <li> {t("product:page16.automated_calculation")} </li>{" "}
                <li> {t("product:page16.notification_and_alert")} </li>
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>
                  {" "}
                  {t("product:page16.ai_based_diagnostics")}{" "}
                  {/* <span className="productheadingspan">
                    {t('product:page16.diagnostics')}
                  </span>{' '} */}
                </h2>{" "}
              </div>
              <ul>
                <li>
                  {" "}
                  {t("product:page16.digital_record_automatic_inspection")}{" "}
                </li>{" "}
                <li> {t("product:page16.data_comparison")} </li>{" "}
                <li> {t("product:page16.ai_based_analysis")} </li>{" "}
              </ul>{" "}
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2> {t("product:page16.summary")} </h2>{" "}
              </div>
              <ul>
                <li> {t("product:page16.get_summarized_analysis")} </li>{" "}
                <li> {t("product:page16.user_understandable_language")} </li>{" "}
                <li> {t("product:page16.track_progress")} </li>{" "}
                <li> {t("product:page16.gamify_maintenance_task")} </li>{" "}
                <li> {t("product:page16.manage_old_inspections")} </li>
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
}
export default Page16;
