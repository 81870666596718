import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";
// import p9i1 from '../images/p9i1.png';
// import p9i2 from '../images/p9i2.png';
// import p9i3 from '../images/p9i3.png';
// import p9i4 from '../images/p9i4.png';
function Page9() {
  const { t } = useTranslation();

  return (
    <div className="page9 solution-page">
      <Solutionsubmenu />
      <div className="container maintainconp9 solution-page-col">
        <div className="row">
          <div className="col-lg-3 col-md-6 page9subcol">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p9i1_hdxmq6.png"
              alt=" "
            />
            <h5>{t("solution:page9.real_time_and_post_delivery")}</h5>
          </div>
          <div className="col-lg-3 col-md-6 page9subcol">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p9i2_vsb3wn.png"
              alt=" "
            />
            <h5>{t("solution:page9.optimized_route_planning")}</h5>
          </div>
          <div className="col-lg-3 col-md-6 page9subcol">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p9i3_dxirzl.png"
              alt=" "
            />
            <h5>{t("solution:page9.cost_effective_vehicle")}</h5>
          </div>
          <div className="col-lg-3 col-md-6 page9subcol">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p9i4_mc7mf0.png"
              alt=" "
            />
            <h5>{t("solution:page9.optimized_order_sorting")}</h5>
          </div>
        </div>
        <p>
          {t("solution:page9.vyorius_provides")}{" "}
          <span className="orangeText">{t("solution:page9.intelligent")} </span>
          {t("solution:page9.data_analytics_for_unmanned_services")}{" "}
          <span className="orangeText">{t("solution:page9.forecast")} </span>
          {t("solution:page9.their_mission")}{" "}
          <span className="orangeText"> {t("solution:page9.time")}</span>,
          <span className="orangeText"> {t("solution:page9.routes")}</span>{" "}
          {t("solution:page9.and")}{" "}
          <span className="orangeText">{t("solution:page9.costs")}</span>.
        </p>
      </div>
    </div>
  );
}
export default Page9;
