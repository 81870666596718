import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/prod-eco.png';

// import p13i1 from '../images/product.png';
function Page13() {
  const { t } = useTranslation();

  return (
    <div className="page13">
      <ProductSubmenu />
      <img
        src={
          "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652822/Vyorius%20Website/27%20may/prod-eco_pqf1va.png"
        }
        alt=""
        className="productImage"
        useMap="#desc"
      />
      <map name="desc">
        <area
          shape="rect"
          coords="1050,200,1130,363"
          alt="Client"
          title="Client"
        />
        <area
          shape="rect"
          coords="770,120,1050,363"
          alt="Operation"
          title="Operation"
        />
        <area
          shape="rect"
          coords="410,20,770,363"
          alt="Ground Station"
          title="Ground Station"
        />
        <area
          shape="rect"
          coords="165,150,409,363"
          alt="Maintenance manager"
          title="Maintenance manager"
        />
        <area
          shape="rect"
          coords="85,220,164,373"
          alt="Merchant"
          title="Merchant"
        />
      </map>
      <p>
        <span className="orangeText" style={{ fontSize: "120%" }}>
          {t("common:menu.fleet_management")}{" "}
          <span className="blackText"> | </span>
          {t("product:page13.process_automation")}{" "}
          <span className="blackText"> | </span>{" "}
          {t("product:page13.operation_optimisation")}
        </span>
        <br />
        <div style={{ marginBottom: 10 }} />
        <span className="orangeText">
          {t("product:page13.hardware_agnostic")}{" "}
        </span>
        {t("product:page13.platform_that_supports")}
        <span className="orangeText">
          {" "}
          {t("product:page13.autonomous_robots")}{" "}
        </span>
        {t("product:page13.to_accomplish_their_missions")}
      </p>
    </div>
  );
}
export default Page13;
