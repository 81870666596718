import React from "react";
import { useTranslation } from "react-i18next";
// import p4i1 from '../images/p4i1.png';
// import p4i2 from '../images/p4i2.png';
// import p4i3 from '../images/p4i3.png';
// import p4i4 from '../images/p4i4.png';
// import p4i5 from '../images/p4i5.png';
// import solu1 from '../images/solu1.png';

function Page4() {
  const { t } = useTranslation();

  return (
    <div className="page4">
      <h1>
        {t("why_vyorius:page4.multiple_problems")} —
        <span className="orangeText">
          {" "}
          {t("why_vyorius:page4.one_solution")}{" "}
        </span>
      </h1>
      <div>
        <img
          className="page4bigimg"
          src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1626534489/Vyorius%20Website/solu1_dfsdzv.png"
          alt=""
        />
      </div>
      {/* //////new code/////// */}
      {/* <div className="page4imgbox">
        <div className="page4images">
          <h5>Poor end-to-end visibility</h5>
          <img  className="page4img"
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p4i1_zoxizz.png"
            alt=""
            style={{ width: "210px" }}
          />
          
        </div>
        <div className="page4images">
          <img className="page4img"
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p4i2_krv5g2.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Disconnected disparate systems</h5>
        </div>
        <div className="page4images">
          <h5>Inability to process large data effectively</h5>
          <img className="page4img"
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p4i3_syqzkx.png"
            alt=""
            style={{ width: "210px" }}
          />
          
        </div>
      
      
        <div className="page4images">
          <img className="page4img"
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p4i4_bdrlud.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Lack of focus on value creation</h5>
        </div>
        <div className="page4images">
          <h5>Large downtime</h5>
          <img className="page4img"
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p4i5_yvu7qo.png"
            alt=""
            style={{ width: "210px" }}
          />
          
        </div>
      </div> */}
      {/* //////////ends here///////// */}
      {/* <div className="row page4row1">
        <div className="col-lg-4 col-md-6">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p4i1_zoxizz.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Poor end-to-end visibility</h5>
        </div>
        <div className="col-lg-4 col-md-6">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p4i2_krv5g2.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Disconnected disparate systems</h5>
        </div>
        <div className="col-lg-4">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p4i3_syqzkx.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Inability to process large data effectively</h5>
        </div>
      </div>
      <div className="row p4row2">
        <div className="col-lg-6 col-md-6 p2r2col1">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p4i4_bdrlud.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Lack of focus on value creation</h5>
        </div>
        <div className="col-lg-6 col-md-6 p2r2col2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p4i5_yvu7qo.png"
            alt=""
            style={{ width: "210px" }}
          />
          <h5>Large downtime</h5>
        </div>
      </div> */}
    </div>
  );
}
export default Page4;
