import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";
// import p10i1 from "../images/p10i1.png";
// import p10i2 from "../images/p10i2.png";
// import p10i3 from "../images/p10i3.png";
// import p10i4 from "../images/p10i4.png";

function Page10() {
  const { t } = useTranslation();

  return (
    <div className="page10 solution-page">
      <Solutionsubmenu />
      <div className="container solution-page-col">
        <div className="row">
          <div className="col-lg-6 page10col1dis page10col1">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <span className="orangeText">
                {t("solution:page10.share_your_mission")}
              </span>
            </div>
            <ul>
              <li>{t("solution:page10.select_what_information")}</li>
              <li>{t("solution:page10.choose_who_to_share")} </li>
              <li>{t("solution:page10.create_a_unique_sharing_link")}</li>
            </ul>

            <div className="p10col1row2">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <span className="orangeText">
                  {t("solution:page10.collaborate_with_your_team")}
                </span>
              </div>
              <ul>
                <li>{t("solution:page10.troubleshoot_technical_issues")}</li>
                <li>{t("solution:page10.identify_area_with_high_level")} </li>
                <li>{t("solution:page10.showcase_your_mission")}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 page10coldis2 page10col2">
            <div className="row p10row2">
              <div className="col-lg-6 col-md-6 page10subcol">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p10i1_tfi573.png"
                  alt=" "
                />
                <h5>{t("solution:page10.clients_records")}</h5>
              </div>
              <div className="col-lg-6 col-md-6 page10subcol">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p10i2_c5o8eb.png"
                  alt=" "
                />
                <h5>{t("solution:page10.eta")}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 page10subcol">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p10i3_pavcuu.png"
                  alt=" "
                />
                <h5>{t("solution:page10.real_time_tracking")}</h5>
              </div>
              <div className="col-lg-6 col-md-6 page10subcol ">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p10i4_uwpjom.png"
                  alt=" "
                />
                <h5>{t("solution:page10.real_time_payload_integrity")}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page10;
