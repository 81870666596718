import React from "react";
import UseCasesSubmenu from "./useCasessubmenu";
import { useTranslation } from "react-i18next";

// import p21i1 from "../images/p21i1.jpeg";

function Page21() {
  const { t } = useTranslation();

  return (
    <div className="page21 use-case">
      <UseCasesSubmenu />
      <div className="container usecasecontainer">
        <div className="headingcolusecases">
          <h1 className="usecaseh1">
            {" "}
            {t("use_case:page21.inspect_any_infrastructure")}{" "}
          </h1>
          <>
            <p className="page20P">
              {" "}
              {t("use_case:page21.road_and_rail_networks_cover")} <br />{" "}
              {t("use_case:page21.with_vyorius")}{" "}
            </p>{" "}
            <ul>
              <li> {t("use_case:page21.cell_towers_and_telecoms")} </li>{" "}
              <li> {t("use_case:page21.road_and_rail_networks")} </li>{" "}
              <li> {t("use_case:page21.bridges")} </li>{" "}
              <li> {t("use_case:page21.dams_and_reservoirs")} </li>{" "}
              <li> {t("use_case:page21.building_and_rooftops")} </li>{" "}
              <li> {t("use_case:page21.industrial_infrastructure")} </li>
            </ul>
          </>
        </div>
        <div className=" usecasecol2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649584/Vyorius%20Website/p21i1_utg7ce.jpg"
            alt=""
          />
        </div>
      </div>{" "}
    </div>
  );
}
export default Page21;
