import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/gs.png';
// import p14i1 from "../images/2.png";
// import thumbnail from "../images/thumbnail1.png";
function Page14() {
  const { t } = useTranslation();

  return (
    <>
      <div className="page14 product-page">
        <ProductSubmenu />
        <h1 className="productHeading">
          {" "}
          {t("product:page14.vyorius_ground_station")}{" "}
        </h1>
        <div className="product-wrapper">
          <div className="product-col2">
            <img
              src={
                "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652811/Vyorius%20Website/27%20may/gs_jiz1jo.png"
              }
              className="productImage"
              alt=""
            />
          </div>{" "}
          <div className="product-col1">
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page14.plan")}</h2>
              </div>
              <ul>
                <li>{t("product:page14.waypoints")}</li>
                <li> {t("product:page14.surveying_areas")} </li>
                <li>{t("product:page14.scanning_corridors")} </li>
                <li> {t("product:page14.regions_of_interest")}</li>
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page14.analyze")}</h2>
              </div>
              <ul>
                <li>{t("product:page14.video_live_stream")}</li>
                <li>{t("product:page14.remote_operation_by_lte")} </li>
                <li> {t("product:page14.automatic_data_logging")}</li>
                <li> {t("product:page14.airspace_and_utm_information")} </li>
                <li>{t("product:page14.weather_wind_elevation")}</li>
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page14.execute")}</h2>
              </div>
              <ul>
                <li>{t("product:page14.takeoff_and_travel")}</li>
                <li>{t("product:page14.return_to_launch")}</li>
                <li>{t("product:page14.land_at_specified_location")}</li>
                <li> {t("product:page14.rally_points_for")}</li>
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page14.connect")}</h2>
              </div>
              <ul>
                <li>{t("product:page14.cloud_connectivity")}</li>
                <li>{t("product:page14.pilot_login_for_compliance")}</li>
                <li>{t("product:page14.load_prepared_missions")}</li>
                <li>{t("product:page14.vehicle_pairing")}</li>
                <li>{t("product:page14.pre_mission_checklists")}</li>
              </ul>
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </>
  );
}
export default Page14;
