import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";
// import page7img1 from '../images/p7i1.png';
// import page7img2 from '../images/p7i2.png';
// import page7img3 from '../images/p7i3.png';
// import page7img4 from '../images/p7i4.png';
// import page7img5 from '../images/p7i5.png';

function Page7() {
  const { t } = useTranslation();

  return (
    <div className="page7 solution-page">
      <Solutionsubmenu />
      <div className="solution-page-col">
        <div className="row page7row ">
          <div className="page7imagediv">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p7i1_lhdhk1.png"
              alt=" "
            />
            <h5>{t("solution:page7.asset")}</h5>
          </div>
          <div className="page7imagediv">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p7i2_gsruby.png"
              alt=" "
            />
            <h5>{t("solution:page7.operators")}</h5>
          </div>
          <div className="page7imagediv">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649580/Vyorius%20Website/p7i3_divqrf.png"
              alt=" "
            />
            <h5>{t("solution:page7.vehicles")}</h5>
          </div>
          <div className="page7imagediv">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p7i4_sdfqob.png"
              alt=" "
            />
            <h5>{t("solution:page7.ecosystems")}</h5>
          </div>
          <div className="page7imagediv p7idiv5">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p7i5_ag2e0i.png"
              alt=" "
            />
            <h5>{t("solution:page7.tracking")}</h5>
          </div>
        </div>
        <p>
          {t("solution:page7.vyorius_comprehensively")}
          <span className="orangeText"> {t("solution:page7.digitizes")} </span>
          {t("solution:page7.and")}{" "}
          <span className="orangeText">{t("solution:page7.networks")} </span>
          {t("solution:page7.all_system_components_with_cloud")}{" "}
          <span className="orangeText"> {t("solution:page7.real_time")} </span>
          {t("solution:page7.to_system_data_to_obtain")}
          <span className="orangeText">
            {" "}
            {t("solution:page7.system_operations")}
          </span>
          .
        </p>
      </div>
    </div>
  );
}
export default Page7;
