import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";
// import p8i1 from '../images/p8i1.png';
// import p8i2 from '../images/p8i2.png';
// import p8i3 from '../images/p8i3.png';
// import p8i4 from '../images/p8i4.png';
// import p8i5 from '../images/p8i5.png';
// import p8i6 from '../images/p8i6.png';
// import p8i7 from '../images/p8i7.png';

function Page8() {
  const { t } = useTranslation();

  return (
    <div className="page8 solution-page">
      <Solutionsubmenu />
      <div className="solution-page-col">
        <div className="container maintaincon ">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 submaintaincon">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p8i4_dcbxjd.png"
                alt=""
              />
              <h5>{t("solution:page8.ai_enabled_diagnostics")}</h5>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 submaintaincon">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p8i5_iqtgn3.png"
                alt=""
              />
              <h5>{t("solution:page8.map_sensors")} </h5>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 submaintaincon">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p8i6_wgrd5e.png"
                alt=""
              />
              <h5>{t("solution:page8.health_analysis")}</h5>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 submaintaincon">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p8i7_e9z0sa.png"
                alt=""
              />
              <h5>{t("solution:page8.efficiency_analysis")}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 submaintaincon">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p8i1_xveuks.png"
                alt=""
              />
              <h5>{t("solution:page8.hardware_life_cycle")}</h5>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 submaintaincon">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p8i2_y98hii.png"
                alt=""
              />
              <h5>{t("solution:page8.software_life_cycle")}</h5>
            </div>
            <div className="col-lg-4 submaintaincon" style={{ width: "100%" }}>
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649581/Vyorius%20Website/p8i3_szfn7h.png"
                alt=""
              />
              <h5>{t("solution:page8.electronics_life_cycle")}</h5>
            </div>
          </div>
        </div>
        <p>
          {" "}
          <span className="orangeText">
            {t("solution:page8.digitize_maintenance")}
          </span>{" "}
          {t("solution:page8.and_records_and_utilize")}
          <span className="orangeText">
            {" "}
            {t("solution:page8.predictive_maintenance")}
          </span>{" "}
          {t("solution:page8.and_summaries_of_all")}{" "}
          <span className="orangeText">
            {t("solution:page8.unmanned_operation")}
          </span>
          .
        </p>
      </div>
    </div>
  );
}
export default Page8;
