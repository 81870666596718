import React from "react";
import UseCasesSubmenu from "./useCasessubmenu";
import { useTranslation } from "react-i18next";
// import p26i1 from "../images/p26i1.png";
function Page26() {
  const { t } = useTranslation();

  return (
    <div className="page26 use-case">
      <UseCasesSubmenu />
      <div className="container usecasecontainer">
        <div className="headingcolusecases">
          <h1 className="usecaseh1">{t("use_case:page26.put_autonomous")}</h1>

          <>
            <p className="page20P">
              {t("use_case:page26.robots_offer_safe")}
              <br />
              {t("use_case:page26.vyorius_offers")}
            </p>
            <ul>
              <li>{t("use_case:page26.construction")} </li>
              <li>{t("use_case:page26.agriculture")} </li>
              <li>{t("use_case:page26.conservation")} </li>
              <li>{t("use_case:page26.facilities")} </li>
              <li>{t("use_case:page26.real_estate")} </li>
              <li>{t("use_case:page26.weather_tracking")} </li>
            </ul>
          </>
        </div>

        <div className="usecasecol2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649585/Vyorius%20Website/p26i1_wfe9fw.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
export default Page26;
