import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
function ProductSubmenu() {
  const { t } = useTranslation();

  return (
    <div className="usecasemenu">
      <h1>
        {" "}
        <span className="orangeText">
          {t("common:submenu.convert_regular_operations")}
        </span>{" "}
      </h1>
      <div className="usecasenav">
        <div class="scrollmenu">
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/useCases/energy"
          >
            {t("common:menu.cargo_and_logistics")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/useCases/energy/infrastructure"
          >
            {t("common:menu.infrastructure")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/useCases/energy/publicSafety"
          >
            {t("common:menu.public_safety")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/useCases/energy/cargoLogistic"
          >
            {t("common:menu.energy")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/useCases/energy/miningQuarrying"
          >
            {t("common:menu.mining_and_quarrying")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/useCases/energy/other"
          >
            {t("common:menu.other_sectors")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
export default ProductSubmenu;
