import React from "react";
import UseCasesSubmenu from "./useCasessubmenu";
import { useTranslation } from "react-i18next";
// import p22i1 from "../images/p22i1.jpeg";

function Page22() {
  const { t } = useTranslation();

  return (
    <div className="page22 use-case">
      <UseCasesSubmenu />
      <div className="container usecasecontainer">
        <div className="headingcolusecases">
          <h1 className="usecaseh1">
            {" "}
            {t("use_case:page22.command_unmmaned")}{" "}
          </h1>

          <>
            <p className="page20P">
              {" "}
              {t("use_case:page22.autonomous_robots")}
              <br />
              {t("use_case:page22.with_real_time_data")}{" "}
            </p>{" "}
            <ul>
              <li> {t("use_case:page22.search_and_rescue")} </li>
              <li> {t("use_case:page22.firefighting")} </li>{" "}
              <li> {t("use_case:page22.law_enforcement")} </li>{" "}
              <li> {t("use_case:page22.security")} </li>{" "}
              <li> {t("use_case:page22.disaster_response")} </li>{" "}
              <li> {t("use_case:page22.resource_tracking")} </li>
            </ul>
          </>
        </div>
        <div className=" usecasecol2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649584/Vyorius%20Website/p22i1_qopyso.jpg"
            alt=""
          />
        </div>
      </div>{" "}
    </div>
  );
}
export default Page22;
