import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/client.png';
// import p15i1 from "../images/5.png";
// import thumbnail from "../images/thumbnail1.png";
function Page17() {
  const { t } = useTranslation();

  return (
    <>
      <div className="page17 product-page">
        <ProductSubmenu />
        <h1 className="productHeading">
          {t("product:page17.vyorius_client_manager")}
        </h1>
        <div className="product-wrapper">
          <div className="product-col2">
            <img
              src={
                "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652810/Vyorius%20Website/27%20may/client_tiwvjm.png"
              }
              alt=""
              className="productImage"
            />
          </div>
          <div className="product-col1">
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page17.discovery")}</h2>
              </div>
              <ul>
                <li>{t("product:page17.showcase_your_services")}</li>
                <li>{t("product:page17.create_a_service_provider_profile")}</li>
                <li>{t("product:page17.allow_users_to_directly_order")}</li>
                <li>{t("product:page17.create_workflow_of_the_orders")}</li>
                <li>{t("product:page17.provide_services")} </li>
              </ul>
            </div>
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page17.management")}</h2>
              </div>
              <ul>
                <li>{t("product:page17.user_can_manage_past")}</li>
                <li>
                  {t(
                    "product:page17.automatically_find_nearest_service_providers"
                  )}
                </li>
                <li>{t("product:page17.get_quotes_faster")}</li>
              </ul>
            </div>

            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page17.transparency")}</h2>
              </div>
              <ul>
                <li>{t("product:page17.real_time_order_tracking")}</li>
                <li>{t("product:page17.real_time_payload")}</li>
                <li>{t("product:page17.route_visibility")}</li>
                <li>
                  {t("product:page17.mission_transparency_and_data_sharing")}
                </li>
              </ul>
            </div>
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2>{t("product:page17.authentication")}</h2>
              </div>
              <ul>
                <li>{t("product:page17.get_orders_delivered_by_otp")}</li>
                <li>
                  {t(
                    "product:page17.set_up_destination_and_payload_requirement"
                  )}
                </li>
                <li>{t("product:page17.get_verified_service_providers")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page17;
