import React from "react";
import { Card } from 'react-bootstrap';

function NewsCard(props) {
    return (
        <>
            <Card style={{ width: '18rem', height:'20rem',margin:'1px', overflow:'auto' }}>
                <Card.Img variant="top" src={props.news.img} />
                <Card.Body>
                    <Card.Title>{props.news.title}</Card.Title>
                    <Card.Text>
                        {props.news.content}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default NewsCard;