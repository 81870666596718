import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
function ProductSubmenu() {
  const { t } = useTranslation();

  return (
    <div className="productsubmenu">
      <div className="scrollmenu">
        <NavLink
          className="text-black"
          exact
          activeClassName="underline"
          to="/product/intro"
        >
          {t("common:submenu.vyorius_product_ecosystem")}
        </NavLink>
      </div>
      <h1>
        {" "}
        <span className="orangeText">
          {t("common:submenu.vyorius_product_ecosystem")}
        </span>{" "}
      </h1>
      <div className="produtmenudiv">
        <div class="scrollmenu">
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusGroundStation"
          >
            {t("common:menu.ground_station")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusOperation"
          >
            {t("common:menu.operation_manager")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusMaintainance"
          >
            {t("common:menu.maintenance_manager")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusClient"
          >
            {t("common:menu.client_manager")}
          </NavLink>
          {/**<NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusAnalytics"
          >
            Analytics
  </NavLink>**/}
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusSimulator"
          >
            {t("common:menu.simulator")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusPrecisionLanding"
          >
            {t("common:menu.precision_landing")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusAIEnabledVideo"
          >
            {t("common:menu.ai_enabled_video")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusLiveStreaming"
          >
            {t("common:menu.live_streaming")}
          </NavLink>
          {/* <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/product/intro/vyoriusIndoorNavigation"
          >
            Indoor navigation
          </NavLink> */}
          {/* <NavLink  className="text-black" exact activeClassName="underline" to="/product/intro/aerialDelivery">Aerial Delivery</NavLink> */}
        </div>
      </div>
    </div>
  );
}
export default ProductSubmenu;
