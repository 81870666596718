import React from "react";
import ProductSubmenu from "./productssubmenu";

function Page31() {
  return (
    <div className="page31 product-page">
      <ProductSubmenu />
      <h1 className="productHeading"> Vyorius Indoor Navigation </h1>
      <div className="product-wrapper">
        <div className="product-col2">
          <img src="" className="productImage" alt="" />
        </div>{" "}
        <div className="product-col1">
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>Indoor navigation</h2>{" "}
            </div>
            <ul>
              <li>Autonomous Navigation</li>
              <li>Collision Avoidance</li>
              <li>Automated Drone Fleet Management</li>
              <li>Hardware Agnostic</li>
              <li>Auto Charging using precision landing</li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>Applications</h2>{" "}
            </div>
            <ul>
              <li>Warehouse Automation</li>
              <li>Security & surveillance</li>
              <li>Roof inspection</li>
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>Benefits</h2>{" "}
            </div>
            <ul>
              <li>Better efficiency</li>
              <li>Greater safety</li>
              <li>Increased ROI</li>
              <li>Higher Reliability</li>
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>Use cases</h2>{" "}
            </div>
            <ul>
              <li>Inventory search & reconciliation</li>
              <li>Cycle counting & audits</li>
              <li>Worker safety & productivity</li>
              <li>Item marking & recognition</li>
              <li>2D/3D space optimization</li>
              <li>Order picking optimization</li>
            </ul>
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
export default Page31;
