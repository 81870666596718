import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { browserHistory as history } from "react-router-dom";

import { NavLink } from "react-router-dom";

import Media from "../News/Media";
function Newssubmenu() {
  const { t } = useTranslation();

  const [p, setp] = useState(0);
  // eslint-disable-next-line
  const [left, setleft] = useState(0);
  // eslint-disable-next-line
  const [percent, setpercent] = useState(false);
  const [lorR, setlorR] = useState(1);
  function leftorright() {
    if (lorR === 1) {
      goLeft();
      setlorR(0);
    } else if (lorR === 0) {
      goRight();
      setlorR(1);
    }
  }

  function goLeft() {
    if (p < -970) {
      return;
    }
    setp(p + -326);
    setleft(p);
    setpercent(true);
  }
  function goRight() {
    if (p === 0) {
      // return;
    }
    setp(p + 326);
    setleft(p);
  }
  // console.log(history);

  return (
    <div className="solutionsubmenu" style={{ padding: "2% 2%" }}>
      <h1>
        {t("media:submenu.latest")}
        <span className="orangeText"> {t("media:submenu.news")}</span>
      </h1>
      <Media />
      {/* <div className="solutionmenunav">
        <div class="scrollmenu ">
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/news/linkedin"
          >
            LinkedIn
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/news/twitter"
          >
            Twitter
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            onClick={leftorright}
            to="/news/youtube"
          >
            YouTube
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/news/instagram"
          >
            Instagram
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/news/facebook"
          >
            Facebook
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/news/media"
          >
            Media
          </NavLink>
        </div>
      </div> */}
    </div>
  );
}
export default Newssubmenu;
