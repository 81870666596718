import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/precision.png';
function Page28() {
  const { t } = useTranslation();

  return (
    <div className="page28 product-page">
      <ProductSubmenu />
      <h1 className="productHeading">
        {" "}
        {t("product:page28.vyorius_precision_landing")}{" "}
      </h1>
      <div className="product-wrapper">
        <div className="product-col2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652821/Vyorius%20Website/27%20may/precision_mpel4i.png"
            }
            className="productImage"
            alt=""
          />
        </div>{" "}
        <div className="product-col1">
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page28.benefits")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page28.fully_autonomous")}</li>{" "}
              <li>{t("product:page28.low_complexity")}</li>{" "}
              <li>{t("product:page28.vision_based")} </li>{" "}
              <li>{t("product:page28.scalable")}</li>{" "}
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page28.use_cases")} </h2>{" "}
            </div>
            <ul>
              <li>{t("product:page28.dvlos_landing")}</li>
              <li>{t("product:page28.drone_in_a_box")}</li>
              <li>{t("product:page28.automated_docking")}</li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page28.salient_features")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page28.easy_installation")}</li>{" "}
              <li>{t("product:page28.high_accuracy")} </li>
              <li>{t("product:page28.minimal_hardware")} </li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page28.accurate_in")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page28.take_off")}</li>
              <li>{t("product:page28.hovering")}</li>
              <li>{t("product:page28.landing")}</li>
            </ul>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
export default Page28;
