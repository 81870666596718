import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Language from "./Language/Language";
import { useTranslation } from "react-i18next";
import { LOGIN_DASHBOARD_HOST } from "../config/config";

function Menu() {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="menu">
      <Navbar expand="md">
        <a class="navbar-brand" href="/" style={{ marginLeft: "10px" }}>
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649578/Vyorius%20Website/logo1_u3byoz.png"
            width="50"
            height="50"
            className="d-inline-block align-top  img-responsive logoimage"
            alt="React Bootstrap logo"
          />
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652824/Vyorius%20Website/27%20may/VyoriusLogotext_fhfyr8.png"
            }
            className="d-inline-block align-top  brandnameimage img-responsive brandnameimage"
            alt="React Bootstrap logo"
          />
        </a>
        <div className="toggle-wrapper">
          {window.innerWidth <= 767 && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOGIN_DASHBOARD_HOST}
            >
              <button className="btn menubutton" onClick={handleShow}>
                {t("common:menu.launch_vyorius")}
              </button>
            </a>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" id="my-nav">
            <div className="nav-links">
              {/* <div className="test">
              <Nav.Link
                as={NavLink}
                className="text-black"
                exact
                activeClassName="underline"
                to="/whyVyorius"
              >
                Why Vyorius ?
              </Nav.Link>
            </div> */}
              <div className="dropdown">
                <a href="/whyVyorius">{t("common:menu.why_vyorius")}</a>
              </div>
              {/* <div className="test">
              <Nav.Link
                as={NavLink}
                className="text-black"
                activeClassName="underline"
                to="/solution/dataSync"
              >
                Solutions
              </Nav.Link>
            </div> */}
              <div class="dropdown">
                <a>
                  {t("common:menu.solutions")}{" "}
                  <img
                    src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649615/Vyorius%20Website/angle-down_koujhe.png"
                    alt=""
                    className="angle-down"
                  />
                </a>
                <ul class="dropdown-selection">
                  <li>
                    <a href="/solution/dataSync">
                      {t("common:menu.data_sync")}
                    </a>
                  </li>
                  <li>
                    <a href="/solution/dataSync/fleetManagement">
                      {t("common:menu.fleet_management")}
                    </a>
                  </li>
                  <li>
                    <a href="/solution/dataSync/maintainance">
                      {t("common:menu.maintenance")}
                    </a>
                  </li>
                  <li>
                    <a href="/solution/dataSync/dataAnalytics">
                      {t("common:menu.data_analytics")}
                    </a>
                  </li>
                  <li>
                    <a href="/solution/dataSync/dataSharing">
                      {t("common:menu.transparent_data_sharing")}
                    </a>
                  </li>
                  <li>
                    <a href="/solution/dataSync/intelligence">
                      {t("common:menu.intelligence")}
                    </a>
                  </li>
                  <li>
                    <a href="/solution/dataSync/clientDiscovery">
                      {t("common:menu.client_discovery")}
                    </a>
                  </li>
                </ul>
              </div>

              <div class="dropdown">
                <a>
                  {t("common:menu.products")}{" "}
                  <img
                    src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649615/Vyorius%20Website/angle-down_koujhe.png"
                    alt=""
                    className="angle-down"
                  />
                </a>
                <ul class="dropdown-selection">
                  <li>
                    <a href="/product/intro">
                      {t("common:menu.vyorius_ecosystem")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusGroundStation">
                      {t("common:menu.ground_station")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusOperation">
                      {t("common:menu.operation_manager")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusMaintainance">
                      {t("common:menu.maintenance_manager")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusClient">
                      {t("common:menu.client_manager")}
                    </a>
                  </li>
                  {/**<li>
                    <a href="/product/intro/vyoriusAnalytics">Analytics</a>
          </li>**/}
                  <li>
                    <a href="/product/intro/vyoriusSimulator">
                      {t("common:menu.simulator")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusPrecisionLanding">
                      {t("common:menu.precision_landing")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusAIEnabledVideo">
                      {t("common:menu.ai_enabled_video")}
                    </a>
                  </li>
                  <li>
                    <a href="/product/intro/vyoriusLiveStreaming">
                      {t("common:menu.live_streaming")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="/product/intro/vyoriusIndoorNavigation">Indoor navigation</a>
                  </li> */}
                  {/* <li>
                  <a href="/product/intro/aerialDelivery">Aerial Delivery</a>
                  </li> */}
                </ul>
              </div>
              {/* <div className="test1">   //using NAVDROPDOWN from bootstrap
            <NavDropdown title="Product" className="dropdown">
              <div className="dropdown-selection">
                <NavDropdown.Item className="selection" href="/product/intro" >Vyorius EcoSystem</NavDropdown.Item>
                <NavDropdown.Item className="selection" href="/product/intro/vyoriusGroundStation">Ground Station</NavDropdown.Item>
                <NavDropdown.Item className="selection" href="/product/intro/vyoriusOperation">Operation Manager</NavDropdown.Item>
                <NavDropdown.Item className="selection" href="/product/intro/vyoriusMaintainance">Maintenance Manager</NavDropdown.Item>
                <NavDropdown.Item className="selection" href="/product/intro/vyoriusClient">Client Manager</NavDropdown.Item>
                <NavDropdown.Item className="selection" href="/product/intro/vyoriusAnalytics">Analytics</NavDropdown.Item>
                <NavDropdown.Item className="selection" href="/product/intro/aerialDelivery">Aerial Delivery</NavDropdown.Item>
              </div>
            </NavDropdown>
            </div> */}
              {/* 
            <div className="test">
              <Nav.Link
                as={NavLink}
                className="text-black"
                activeClassName="underline"
                to="/useCases/energy"
              >
                Use Cases
              </Nav.Link>
            </div> */}
              <div class="dropdown">
                <a>
                  {t("common:menu.use_cases")}{" "}
                  <img
                    src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649615/Vyorius%20Website/angle-down_koujhe.png"
                    alt=""
                    className="angle-down"
                  />{" "}
                </a>
                <ul class="dropdown-selection">
                  <li>
                    <a href="/useCases/energy">
                      {t("common:menu.cargo_and_logistics")}
                    </a>
                  </li>
                  <li>
                    <a href="/useCases/energy/infrastructure">
                      {t("common:menu.infrastructure")}
                    </a>
                  </li>
                  <li>
                    <a href="/useCases/energy/publicSafety">
                      {t("common:menu.public_safety")}
                    </a>
                  </li>
                  <li>
                    <a href="/useCases/energy/cargoLogistic">
                      {t("common:menu.energy")}
                    </a>
                  </li>
                  <li>
                    <a href="/useCases/energy/miningQuarrying">
                      {t("common:menu.mining_and_quarrying")}
                    </a>
                  </li>
                  <li>
                    <a href="/useCases/energy/other">
                      {t("common:menu.other_sectors")}
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="test">
              <Nav.Link
                as={NavLink}
                className="text-black"
                exact
                activeClassName="underline"
                to="/team"
              >
                Team
              </Nav.Link>
            </div> */}

              {/* <div className="dropdown">
              <a href="/team">Teams</a>
            </div> */}

              {/* <div className="test">
              <Nav.Link
                as={NavLink}
                className="text-black"
                exact
                activeClassName="underline"
                to="/OurPartners"
              >
                Traction
              </Nav.Link>
            </div> */}
              <div className="dropdown">
                <a href="/OurPartners">{t("common:menu.partners")}</a>
              </div>
              {/* <div className="test">
              <Nav.Link
                as={NavLink}
                className="text-black"
                exact
                activeClassName="underline"
                to="/contact"
              >
                Contact us
              </Nav.Link>
            </div> */}

              <div className="dropdown">
                <a href="/news">{t("common:menu.news")}</a>
              </div>

              <div className="dropdown">
                <a href="/contact">{t("common:menu.contact_us")}</a>
              </div>
            </div>
          </Nav>

          <Language />

          {window.innerWidth > 600 && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={LOGIN_DASHBOARD_HOST}
            >
              <button className="btn menubutton" onClick={handleShow}>
                {t("common:menu.launch_vyorius")}
              </button>
            </a>
          )}
        </Navbar.Collapse>
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h1>
            <span className="orangeText">{t("common:menu.launched")}</span>
          </h1>
        </Modal.Header>
      </Modal>
    </div>
  );
}
export default Menu;
