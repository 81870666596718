import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { browserHistory as history } from "react-router-dom";

import { NavLink } from "react-router-dom";

function Solutionsubmenu() {
  const [p, setp] = useState(0);
  // eslint-disable-next-line
  const [left, setleft] = useState(0);
  // eslint-disable-next-line
  const [percent, setpercent] = useState(false);
  const [lorR, setlorR] = useState(1);
  const { t } = useTranslation();

  function leftorright() {
    if (lorR === 1) {
      goLeft();
      setlorR(0);
    } else if (lorR === 0) {
      goRight();
      setlorR(1);
    }
  }

  function goLeft() {
    if (p < -970) {
      return;
    }
    setp(p + -326);
    setleft(p);
    setpercent(true);
  }
  function goRight() {
    if (p === 0) {
      // return;
    }
    setp(p + 326);
    setleft(p);
  }
  // console.log(history);

  return (
    <div className="solutionsubmenu">
      <h1>
        {t("common:submenu.ai_enabled")}{" "}
        <span className="orangeText">{t("common:submenu.universal")}</span>{" "}
        {t("common:submenu.architecture_for_unmanned_autonomous")}{" "}
        <span className="orangeText"> {t("common:submenu.operations")}</span>
      </h1>
      <div className="solutionmenunav">
        <div class="scrollmenu ">
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/solution/dataSync"
          >
            {t("common:menu.data_sync")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/solution/dataSync/fleetManagement"
          >
            {t("common:menu.fleet_management")}
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            onClick={leftorright}
            to="/solution/dataSync/maintainance"
          >
            {t("common:menu.maintenance")}
          </NavLink>

          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/solution/dataSync/dataAnalytics"
          >
            {t("common:menu.data_analytics")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/solution/dataSync/dataSharing"
          >
            {t("common:menu.transparent_data_sharing")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/solution/dataSync/intelligence"
          >
            {t("common:menu.intelligence")}
          </NavLink>
          <NavLink
            className="text-black"
            exact
            activeClassName="underline"
            to="/solution/dataSync/clientDiscovery"
          >
            {t("common:menu.client_discovery")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
export default Solutionsubmenu;
