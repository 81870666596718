import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";
// import p11i1 from '../images/p11i1.png';
// import p11i2 from '../images/p11i2.png';
// import p11i3 from '../images/p11i3.png';
// import p11i4 from '../images/p11i4.png';
// import p11i5 from '../images/p11i5.png';

function Page11() {
  const { t } = useTranslation();

  return (
    <div className="page11 solution-page">
      <Solutionsubmenu />
      <div className="row imagerow solution-page-col">
        <div className="imagerowdiv">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649582/Vyorius%20Website/p11i1_vxbl5d.png"
            alt=""
          />
          <h5>{t("solution:page11.battery")}</h5>
        </div>
        <div className="imagerowdiv">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649583/Vyorius%20Website/p11i2_sjv788.png"
            alt=""
          />
          <h5>{t("solution:page11.vehicle")}</h5>
        </div>
        <div className="imagerowdiv">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649583/Vyorius%20Website/p11i3_dytszj.png"
            alt=""
          />
          <h5>{t("solution:page11.mission")}</h5>
        </div>

        <div className="imagerowdiv imagerowdiv45">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649583/Vyorius%20Website/p11i4_jp4prb.png"
            alt=""
          />
          <h5>{t("solution:page11.environment")}</h5>
        </div>
        <div className="imagerowdiv imagerowdiv45 imagerowdiv5">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649583/Vyorius%20Website/p11i5_tt4wib.png"
            alt=""
          />
          <h5>{t("solution:page11.prediction")}</h5>
        </div>
      </div>

      <p>
        {t("solution:page11.vyorius_provides")}{" "}
        <span className="orangeText">
          {t("solution:page11.real_time_visualization")}{" "}
        </span>
        {t("solution:page11.tools_combined_with")}{" "}
        <span className="orangeText">
          {" "}
          {t("solution:page11.single_line_of_code")}{" "}
        </span>
        {t("solution:page11.to_streamline_operations")}
        <span className="orangeText"> {t("solution:page11.scattered")} </span>
        {t("solution:page11.across_systems")}
      </p>
    </div>
  );
}
export default Page11;
