import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";
// import p12i1 from '../images/p12i11.png';
function Page12() {
  const { t } = useTranslation();

  return (
    <div className="page12 solution-page">
      <Solutionsubmenu />

      <div className="page12dis solution-page-col">
        <h1>
          <span className="orangeText">
            {t("solution:page12.client_discovery")}
          </span>
        </h1>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649583/Vyorius%20Website/p12i11_yz87wg.png"
              alt=" "
            />
          </div>
          <div className="col-lg-6 col-md-6 page12col2">
            <h2>
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
                style={{ marginLeft: "-40px" }}
              />
              <span className="orangeText" style={{ marginLeft: "15px" }}>
                {t("solution:page12.bring_your_clients")}
              </span>
            </h2>
            <ul>
              <li>{t("solution:page12.the_only_robotics_service_platform")}</li>
              <li>{t("solution:page12.order_anytime")}</li>
              <li>{t("solution:page12.find_nearest_service_provider")} </li>
              <li>{t("solution:page12.get_live_order_tracking")}</li>
              <li>{t("solution:page12.maintain_previous_order_record")} </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page12;
