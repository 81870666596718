import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import Img from '../images/operation.png';
// import p15i1 from "../images/operationpic.png";
// import thumbnail from "../images/thumbnail1.png";

function Page15() {
  const { t } = useTranslation();

  return (
    <>
      <div className="page15 product-page">
        <ProductSubmenu />
        <h1 className="productHeading">
          {" "}
          {t("product:page15.vyorius_operations_manager")}{" "}
        </h1>
        <div className="product-wrapper">
          <div className="product-col2">
            <img
              src={
                "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652820/Vyorius%20Website/27%20may/operation_nt56hn.png"
              }
              className="productImage"
              alt=""
            />
          </div>{" "}
          <div className="product-col1">
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2> {t("product:page15.live_operations")} </h2>{" "}
              </div>
              <ul>
                <li>
                  {" "}
                  {t("product:page15.real_time_operational_visibility")}{" "}
                </li>{" "}
                <li> {t("product:page15.anytime_anywhere")} </li>{" "}
                <li> {t("product:page15.track_progress")} </li>{" "}
                <li> {t("product:page15.maintain_order_requests")} </li>{" "}
                <li> {t("product:page15.manage_new_orders")} </li>{" "}
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2> {t("product:page15.multiple_teams")} </h2>{" "}
              </div>
              <ul>
                {" "}
                <li> {t("product:page15.distribute_teams_by")} </li>{" "}
                <li> {t("product:page15.supervise_multiple_ecosystems")} </li>
              </ul>
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2> {t("product:page15.real_time_data")} </h2>{" "}
              </div>
              <ul>
                <li> {t("product:page15.real_time_vehicle")}</li>{" "}
                <li> {t("product:page15.route_visibility")} </li>{" "}
              </ul>{" "}
            </div>{" "}
            <div className="productcol">
              <div className="desc-heading">
                <img
                  src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                  className="thumbnail"
                  alt=""
                />
                <h2> {t("product:page15.fleet_management")} </h2>{" "}
              </div>
              <ul>
                {" "}
                <li> {t("product:page15.add_or_remove_vehicles")} </li>{" "}
                <li> {t("product:page15.track_active")} </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
}
export default Page15;
