import React from "react";
import ReactMapboxGl, { Marker, Cluster } from "react-mapbox-gl";
// import mapboxgl from 'mapbox-gl';
// import Img from '../images/cluster.png';

// import sdg3 from '../images/sdg3.png';

// import sdg9 from '../images/sdg9.png';

// import sdg11 from '../images/sdg11.png';

// import sdg13 from '../images/sdg13.png';
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";
import { useTranslation } from "react-i18next";
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

/* eslint import/no-webpack-loader-syntax: off */
// import worker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
// mapboxgl.workerClass =
//   require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable

// import eff from '../images/efficiency.png';
// import scal from '../images/maximize.png';
// import prod from '../images/productivity.png';
// import safe from '../images/shield.png';
// import flex from '../images/directions.png';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function Page2() {
  const { t } = useTranslation();

  const Map = ReactMapboxGl({
    attributionControl: false,
    accessToken:
      "pk.eyJ1Ijoidnlvcml1cyIsImEiOiJja2U1ZzhndGcwYXpiMnpyMzcxMzl2eHNjIn0.pcmoQvz_e6Kvvw-KRVCzgg",
  });

  const markers = [
    [103.786, 1.2984], // F drones
    [77.5923, 13.0487], // Bon v aero
    [77.4071, 28.5323], // Skytex
    [101.6168, 3.0396], // Vstream revolution
    [77.2754, 28.5388], // Enord
    [77.0752, 28.5079], // Aerialiq
    [89.7662, 35.1377], // Soaring to success
    // Flig Flight School
    [76.4505, 10.1826], // AMOS
    [127.8302, 26.4643], // OIST
  ];

  const clusterMarker = (coordinates, pointCount) => (
    <Marker coordinates={coordinates}>
      <img
        id="cluster"
        src={
          "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652810/Vyorius%20Website/27%20may/cluster_xuoaj8.png"
        }
        alt="cluster"
      ></img>
      <p id="points">{pointCount}</p>
    </Marker>
  );

  return (
    <div className="page2" id="page2">
      <h1>{t("home_page:page2.operating_across_the_globe")}</h1>
      <br />
      <div id="mapbox">
        <Map
          zoom={[0]}
          style="mapbox://styles/vyorius/ckhngdbg80u1z19pir6wee5qm"
          containerStyle={{
            height: "50vh",
          }}
        >
          <Cluster ClusterMarkerFactory={clusterMarker}>
            {markers.map((data, key) => (
              <Marker key={key} coordinates={data}>
                <img
                  id="marker"
                  src={
                    "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652810/Vyorius%20Website/27%20may/cluster_xuoaj8.png"
                  }
                  alt="marker"
                />
              </Marker>
            ))}
          </Cluster>
        </Map>
      </div>
      <h1>
        <span style={{ fontSize: "80%" }}>
          <span className="orangeText">{t("home_page:page2.stop")}</span>{" "}
          {t("home_page:page2.wasting_time_and_resources")}{" "}
          <span className="orangeText">
            {t("home_page:page2.undifferentiated_heavy_lifting")}
          </span>
        </span>
      </h1>
      <p>
        {t("home_page:page2.be_a_part_of_the_world")}{" "}
        <span className="orangeText">
          {t("home_page:page2.network_of_unmanned_systems")}
        </span>{" "}
        {t("home_page:page2.and_start_delivering")}
      </p>
      <h3>{t("home_page:page2.while_ensuring")}</h3>
      <div className="page2row2">
        <div className="page2imgdiv2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1626534488/Vyorius%20Website/efficiency_vay9cc.png"
            alt=""
            className="page2img2"
          />
          <h5 className="page2imgtext">{t("home_page:page2.efficiency")}</h5>
        </div>
        <div className="page2imgdiv2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1626534488/Vyorius%20Website/productivity_1_aqdqn8.png"
            alt=""
            className="page2img2"
          />
          <h5 className="page2imgtext">{t("home_page:page2.productivity")}</h5>
        </div>
        <div className="page2imgdiv2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1626534489/Vyorius%20Website/maximize_zjernb.png"
            alt=""
            className="page2img2"
          />
          <h5 className="page2imgtext">{t("home_page:page2.scalability")}</h5>
        </div>

        <div className="page2imgdiv2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1626534488/Vyorius%20Website/shield_mnoopi.png"
            alt=""
            className="page2img2"
          />
          <h5 className="page2imgtext">{t("home_page:page2.safety")}</h5>
        </div>
        <div className="page2imgdiv2">
          <img
            src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1626534489/Vyorius%20Website/directions_shn8mu.png"
            alt=""
            className="page2img2"
          />
          <h5 className="page2imgtext">{t("home_page:page2.flexibility")}</h5>
        </div>
      </div>

      <h1 style={{ marginTop: "15px" }}>
        <span style={{ fontSize: "80%" }}>
          <span className="orangeText">
            {t("home_page:page2.contributing")}{" "}
          </span>
          {t("home_page:page2.to_the_better_world")}
        </span>
      </h1>

      <div className="page2row2">
        <div className="page2imgdiv2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652823/Vyorius%20Website/27%20may/sdg3_xqikmy.png"
            }
            alt=""
            className="page2img2"
            style={{ borderRadius: "10px" }}
          />
        </div>
        <div className="page2imgdiv2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652823/Vyorius%20Website/27%20may/sdg9_zrfaof.png"
            }
            alt=""
            className="page2img2"
            style={{ borderRadius: "10px" }}
          />
        </div>
        <div className="page2imgdiv2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652823/Vyorius%20Website/27%20may/sdg11_vssyyw.png"
            }
            alt=""
            className="page2img2"
            style={{ borderRadius: "10px" }}
          />
        </div>

        <div className="page2imgdiv2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652824/Vyorius%20Website/27%20may/sdg13_or4icm.png"
            }
            alt=""
            className="page2img2"
            style={{ borderRadius: "10px" }}
          />
        </div>
      </div>
      {/* <div className="page2row ">
        <div className="col-lg-4 page2imagediv">
          <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p2i1_bunwwr.jpg" alt="" className="page2img" />
          <h5 className="row1h5">Efficiency</h5>
        </div>
        <div className="col-lg-4 page2imagediv">
          <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p2i2_ytx6wa.png" alt="" className="page2img" />
          <h5 className="row1h5">Productivity</h5>
        </div>
        <div className="col-lg-4 page2imagediv">
          <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p2i3_mt6tso.jpg" alt="" className="page2img" />
          <h5 className="row1h5">Scalability</h5>
        </div>
      </div>
      <div className="row page2row page2row2">
        <div className="col-lg-6 p2r2col1 page2imagediv">
          <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p2i4_s5ek6x.png" alt="" className="page2img" />
          <h5 className="row2h5">Safety</h5>
        </div>
        <div className="col-lg-6 p2r2col2 page2imagediv">
          <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649579/Vyorius%20Website/p2i5_hguwac.jpg" alt="" className="page2img" />
          <h5 className="row2h5">Flexibility</h5>
        </div>
      </div> */}
    </div>
  );
}
export default Page2;
