import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import mainpagegif from "../images/ezgif.com-gif-maker.gif";

import {
  CLIENT_DASHBOARD_HOST,
  MERCHANT_DASHBOARD_HOST,
} from "../config/config";

function MainPage() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let width = window.innerWidth;
  console.log(width);
  const scrollPage2ToView = () => {
    window.scrollTo(0, document.body.scrollHeight / 3);
  };

  return (
    <div className="page1">
      <div className="product-wrapper">
        <div className="page1col">
          <h5 className="mainPageHeading6">
            {t("home_page:mainPage.connecting_the_disconnected")}
          </h5>
          <h1 className="mainHeading">
            <span className="orangeText">{t("common:common.vyorius")}</span>
            <br />
            <span className="textinSingleline">
              {t("home_page:mainPage.putting_the")} <br />"
              {t("home_page:mainPage.unmanned")}"
            </span>
            <br />
            <span>
              <span>
                {t("home_page:mainPage.back_in_unmanned")} <br />
                {t("home_page:mainPage.robotic_operations")}
              </span>
            </span>
            <br />
          </h1>
          <p className="mainPageP">
            {t("home_page:mainPage.with_all_of_the_operations")}
          </p>
          <a
            href={CLIENT_DASHBOARD_HOST}
            rel="noreferrer"
            target="_blank"
            style={{ color: "#FF914D" }}
          >
            <button type="button" className=" btn  button1">
              {t("home_page:mainPage.book_your_order")}
            </button>
          </a>
          <a
            href={MERCHANT_DASHBOARD_HOST}
            rel="noreferrer"
            target="_blank"
            style={{ color: "#FF914D" }}
          >
            <button type className=" btn button2">
              {t("home_page:mainPage.start_delivering")}
            </button>
          </a>
          <br />
        </div>
        <div className="page1col">
          <div className="page1imagecol">
            <img src={mainpagegif}></img>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MainPage;
