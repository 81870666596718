import React from "react";
import ProductSubmenu from "./productssubmenu";
import { useTranslation } from "react-i18next";
// import img from '../images/simulator.png';

function Page27() {
  const { t } = useTranslation();

  return (
    <div className="page27 product-page">
      <ProductSubmenu />
      <h1 className="productHeading">
        {" "}
        {t("product:page27.vyorius_simulator")}{" "}
      </h1>
      <div className="product-wrapper">
        <div className="product-col2">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652824/Vyorius%20Website/27%20may/simulator_iifhg5.png"
            }
            className="productImage"
            alt=""
          />
        </div>{" "}
        <div className="product-col1">
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2> {t("product:page27.environmental_models")} </h2>{" "}
            </div>
            <ul>
              <li>
                <b>{t("product:page27.rover")}</b> :{" "}
                {t("product:page27.road_and_buildings_and_traffic")}
              </li>
              <li>
                <b>{t("product:page27.water")}</b> :{" "}
                {t(
                  "product:page27.sea_current_and_traffic_and_lead_lag_vehicles"
                )}
              </li>
              <li>
                <b>{t("product:page27.air")}</b> :{" "}
                {t("product:page27.air_gusts")}
              </li>
            </ul>
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page27.sensor_model_for")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page27.perception")}</li>{" "}
              <li>{t("product:page27.localization")}</li>{" "}
              <li>{t("product:page27.navigation_sensing")}</li>{" "}
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page27.scenario_model_include")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page27.rain_and_dust_and_fog_and_clouds")}</li>
              <li>{t("product:page27.road_wetness_and_time_of_the_day")}</li>
              <li>{t("product:page27.reliability_and_maintainability")}</li>
            </ul>{" "}
          </div>{" "}
          <div className="productcol">
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <h2>{t("product:page27.summary")}</h2>{" "}
            </div>
            <ul>
              <li>{t("product:page27.simulate_all_px4_vehicles")}</li>{" "}
              <li>{t("product:page27.complete_gamification")}</li>{" "}
              <li>{t("product:page27.bridge_with_ros2")}</li>{" "}
            </ul>
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
export default Page27;
