import React from "react";
import UseCasesSubmenu from "./useCasessubmenu";
import image100 from "../images/image100.gif";
import { useTranslation } from "react-i18next";
function Page23() {
  const { t } = useTranslation();

  return (
    <div className="page23 use-case">
      <UseCasesSubmenu />
      <div className="container usecasecontainer">
        <div className="headingcolusecases">
          <h1 className="usecaseh1">
            {t("use_case:page23.deliver_anywhere")}{" "}
          </h1>

          <>
            {" "}
            <p className="page20P">
              {t("use_case:page23.autonomous_robots")}
              <br />
              {t("use_case:page23.with_vyorius")}
            </p>
            <ul>
              <li>{t("use_case:page23.jit_replacement_parts")}</li>
              <li>{t("use_case:page23.long_range_logistics")}</li>
              <li>{t("use_case:page23.mail_and_courier_sevices")} </li>
              <li>{t("use_case:page23.medical_supplies")}</li>
              <li>{t("use_case:page23.rapid_on_demand_delivery")} </li>
            </ul>
          </>
        </div>
        <div className="usecasecol2">
          <img className="player" src={image100} alt="" frameborder="0" />
        </div>
      </div>
    </div>
  );
}
export default Page23;
