import React from 'react';
function YouTube (props){

    var videoSrc = "https://www.youtube.com/embed/" +
        props.video + "?autoplay=" +
        props.autoplay + "&rel=" +
        props.rel + "&modestbranding=" +
        props.modest;
    return (
      <div className="ytvideodiv">
        <iframe className="player" title="none" type="text/html" 
  src={videoSrc}
  frameborder="0"/>
      </div>
    );
  }
export default YouTube;
