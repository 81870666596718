import React from "react";
import ProductSubmenu from "./productssubmenu";
import Youtube from "./ytVideo.jsx";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line
// import p19i1 from "../images/p19i1.png";
// import p19i2 from "../images/p19i2.png";
function Page19() {
  const { t } = useTranslation();

  return (
    <div className="page19">
      <ProductSubmenu />
      <div className="">
        <h2>{t("product:page19.vyorius_hardware_product")}</h2>
        <div className="row page19videorow">
          <div className="col-lg-4">
            <Youtube video="NaqkQjFQEaY" autoplay="0" rel="0" modest="1" />
          </div>
          <div className="col-lg-8">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649584/Vyorius%20Website/p19i2_jfm1ua.png"
              alt=""
            />
          </div>
        </div>
        <p>
          {t("product:page19.this_is_a")}{" "}
          <span className="orangeText">
            {t("product:page19.custom_drone")}{" "}
          </span>
          {t("product:page19.created_by_vyorius")}
          <span className="orangeText"> {t("product:page19.rent")} </span>{" "}
          {t("product:page19.the_drone_on_a")}{" "}
          <span className="orangeText">
            {" "}
            {t("product:page19.subscription")}
          </span>{" "}
          {t("product:page19.basis")}{" "}
        </p>
      </div>
    </div>
  );
}
export default Page19;
