import React from "react";
import { useTranslation } from "react-i18next";
// import page3img from '../images/page31.png';
function Page3() {
  const { t } = useTranslation();

  return (
    <div className="page3">
      <div className="imagediv">
        <img
          src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649610/Vyorius%20Website/page31_cwxooa.png"
          alt="Page 3"
        />
      </div>
      <div className="page3text">
        <h1>
          {t("why_vyorius:page3.a_platform_built_for")}{" "}
          <span className="orangeText">
            {t("why_vyorius:page3.hardware_agnostic")}{" "}
          </span>
          {t("why_vyorius:page3.unmanned_operations")}
        </h1>
        <p>{t("why_vyorius:page3.vyorius_architecture_is")}</p>
      </div>
    </div>
  );
}
export default Page3;
