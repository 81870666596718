import React from "react";
import Solutionsubmenu from "./solutionsubmenu";
import { useTranslation } from "react-i18next";

// import page6img from '../images/1.png';
function Page6() {
  const { t } = useTranslation();

  return (
    <div className="page6 solution-page">
      <Solutionsubmenu />
      <div className="container p6con solution-page-col">
        <div className="row">
          <div className="col-lg-6 p6col1 " style={{ paddingTop: "2.7%" }}>
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <span className="orangeText">
                {t("solution:page6.authenticate_every_step")}
              </span>
            </div>
            <ul>
              <li>{t("solution:page6.pilot_authentication")} </li>
              <li>{t("solution:page6.ground_station_records_position")}</li>
              <li>{t("solution:page6.authentication_of_each")}</li>
              <li>{t("solution:page6.video_transmission_encryption")}</li>
              <li>{t("solution:page6.every_deviation_gets_recorded")}</li>
            </ul>
            <div className="desc-heading">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/thumbnail1_u5xkis.png"
                className="thumbnail"
                alt=""
              />
              <span className="orangeText">
                {t("solution:page6.automatically_uploads_mission_files")}
              </span>
            </div>
            <ul>
              <li>{t("solution:page6.immediate_visibility_of")}</li>
              <li>{t("solution:page6.identify_potential_issues")}</li>
              <li>{t("solution:page6.eliminate_manual_recording")} </li>
              <li>{t("solution:page6.reduce_data_entry_error")}</li>
            </ul>
          </div>
          <div className="col-lg-6 p6col2">
            <img
              src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649613/Vyorius%20Website/1_uptwd8.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page6;
