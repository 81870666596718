import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
// import crunchbase from "../images/crunchbase-seeklogo.svg";
// import FS6 from '../images/f6s-icon.svg';
// import angelListLogo from '../images/angelListLogo.jpg';
// import crunchbaseLogo from '../images/crunchbaseLogo.jpg';
// import instagramLogo from '../images/instagramLogo.jpeg';
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
function Contact() {
  const { t } = useTranslation();

  const [message, setmessage] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  function handleChange(e) {
    e.persist();
    console.log(e.target);
    setmessage((preValue) => {
      if (e.target.name === "fullname") {
        return {
          fullName: e.target.value,
          email: preValue.email,
          message: preValue.message,
        };
      } else if (e.target.name === "email") {
        return {
          fullName: preValue.fullName,
          email: e.target.value,
          message: preValue.message,
        };
      } else if (e.target.name === "message") {
        return {
          fullName: preValue.fullName,
          email: preValue.email,
          message: e.target.value,
        };
      }
    });
  }
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "vyorius_template",
        e.target,
        "user_AAwft9Uc7HjBluJFJ3TE2"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  return (
    <div className="contactdiv container" style={{ padding: "2% 2%" }}>
      <h1 style={{ textAlign: "center" }}>
        <div className="orangeText" id="contact-heading">
          {t("contact:contact_us")}
        </div>
      </h1>
      {
        // <hr />
      }

      <div className="row">
        <div className="col-lg-6">
          <div className="mailingdiv">
            <p>
              <span className="orangeText">
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("contact:mailing_address")}:
                </span>
              </span>
              <br /> {t("contact:name")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}> {t("contact:india")}:</span>{" "}
              {t("contact:add_india")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}> {t("contact:usa")}:</span>{" "}
              {t("contact:add_usa")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}> {t("contact:japan")}:</span>{" "}
              {t("contact:add_japanese")}
            </p>
            {/* <p><span style={{fontWeight:"bold"}}>Vyorius Poland:</span> Szelągowska 49, 61-626 Poznań, Polska</p> */}
          </div>
          <div className="emaildiv">
            <p>
              <span className="orangeText">
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("contact:email")}:
                </span>{" "}
              </span>
            </p>
            <p> {t("contact:email1")}</p>
            <p> {t("contact:email2")}</p>
          </div>
          <div className="phonediv">
            <p>
              <span className="orangeText">
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("contact:phone")}:
                </span>
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("contact:nishant")}:
              </span>{" "}
              {t("contact:ph_nishant")}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t("contact:pankaj")}:
              </span>{" "}
              {t("contact:ph_pankaj")}
            </p>
          </div>
          <div className="socialmediadiv">
            <p>
              <span className="orangeText">
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {t("contact:follow_us")}:
                </span>
              </span>
            </p>
            <a href="https://www.linkedin.com/company/vyorius/">
              <LinkedInIcon
                style={{
                  color: "#0e76a8",
                  height: "3rem",
                  width: "3rem",
                }}
                className="socialmediaIcon"
              />
            </a>
            <a href="https://www.facebook.com/Vyorius/">
              <FacebookIcon
                style={{ color: "#3b5998", height: "3rem", width: "3rem" }}
                className="socialmediaIcon"
              />
            </a>
            <a href="https://www.instagram.com/vyorius/">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649578/Vyorius%20Website/instagramLogo_b225ps.jpg"
                alt=""
                style={{ height: "3rem", width: "3rem" }}
                className="socialmediaIcon"
              />
            </a>
            <a href="https://angel.co/company/vyorius-drones">
              <img
                src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649615/Vyorius%20Website/angelListLogo_jqrdzu.jpg"
                alt=""
                style={{ height: "3rem", width: "3rem" }}
                className="socialmediaIcon"
              />
            </a>
            <a href="https://www.crunchbase.com/organization/vyorius-drones">
              <img
                src={
                  "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652810/Vyorius%20Website/27%20may/crunchbase-seeklogo_ruwqtj.svg"
                }
                alt=""
                style={{ height: "3rem", width: "3rem", padding: "0.25rem" }}
                className="socialmediaIcon"
              />
            </a>
            <a
              href="https://www.f6s.com/vyorius?follow=1"
              target="_blank"
              rel="noopener noreferrer"
              title="Follow Vyorius on F6S"
            >
              <img
                src={
                  "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652810/Vyorius%20Website/27%20may/f6s-icon_apdjvm.svg"
                }
                alt="Follow Vyorius on F6S"
                style={{ height: "3rem", width: "3rem", padding: "0.25rem" }}
                className="socialmediaIcon"
              />
            </a>
            {
              // <a href="https://www.f6s.com/vyoriusdronesllp?follow=1" target="_blank" title="Follow vyorius drones LLP on F6S">
              // <img src="https://www.f6s.com/images/f6s-follow-white.png" border="0" width="78" height="30 " alt="Follow vyorius drones LLP on F6S" style={{width:"100px", height: "40px", padding: "0px"}} className="socialmediaIcon"/></a>
            }
          </div>
        </div>
        <div className="col-lg-6" id="contact-form">
          <div style={{ margin: "9%", marginTop: "0%" }}>
            <form noValidate autoComplete="off">
              {
                // <label>Full Name</label>
              }
              <TextField
                id="standard-basic"
                label={t("contact:fullName")}
                name="fullname"
                value={message.fullName}
                onChange={handleChange}
                style={{ width: "100%", marginTop: "1rem" }}
              />
              {
                // <label>Email</label>
              }

              <TextField
                id="standard-basic"
                label={t("contact:email")}
                name="email"
                value={message.email}
                onChange={handleChange}
                style={{ width: "100%", marginTop: "1rem" }}
              />
              {
                // <label>Message</label>
              }

              <TextField
                id="standard-multiline-flexible"
                label={t("contact:message")}
                name="message"
                onChange={(event) => handleChange(event)}
                multiline
                rows={6}
                style={{ width: "100%", marginTop: "1rem" }}
                value={message.message}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "5%", backgroundColor: "#FF914D" }}
                className="sendButton"
                onSubmit={sendEmail}
              >
                {t("contact:send")}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
