import React from "react";
import page5img1 from "../images/image_26_sbyygt.svg";
import { useTranslation } from "react-i18next";
// import page5img2 from '../images/image_25_ckaudb.svg';
// import page5img3 from '../images/image_24_z5jp5x.svg';
// import page5img4 from '../images/image_29_bccwm9.svg';

function Page5() {
  const { t } = useTranslation();

  return (
    <div className="page5">
      <h1>
        {" "}
        {t("common:common.vyorius")}{" "}
        <span className="orangeText"> {t("why_vyorius:page5.powers")} </span>{" "}
        {t("why_vyorius:page5.every_unmanned_system")}
      </h1>
      <p>
        {t("why_vyorius:page5.our")}{" "}
        <span className="orangeText" style={{ fontWeight: 500 }}>
          {t("why_vyorius:page5.cloud_enabled_internet_independant")}
        </span>{" "}
        {t("why_vyorius:page5.solution_smoothly_integrates")}{" "}
      </p>{" "}
      <h2> {t("why_vyorius:page5.vyorius_is_creating")} </h2>
      <div className="img-super-div">
        <div className="img-div">
          <img src={page5img1} alt=" " className="vector-image" />
          <h5> {t("why_vyorius:page5.iort")} </h5>
        </div>
        <div className="img-div">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652811/Vyorius%20Website/27%20may/image_25_ckaudb_ok1gmo.svg"
            }
            alt=" "
            className="vector-image"
          />
          <h5>
            {" "}
            {t("why_vyorius:page5.ai_supported_operations_optimization")}{" "}
          </h5>
        </div>
        <div className="img-div">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652811/Vyorius%20Website/27%20may/image_24_z5jp5x_bcuujb.svg"
            }
            alt=" "
            className="vector-image"
          />
          <h5> {t("why_vyorius:page5.integrated_workflows")} </h5>
        </div>
        <div className="img-div">
          <img
            src={
              "https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1653652814/Vyorius%20Website/27%20may/image_29_bccwm9_rzbd2l.svg"
            }
            alt=" "
            className="vector-image"
          />
          <h5> {t("why_vyorius:page5.predictive_maintenance")} </h5>
        </div>
      </div>
      {/* <div className="container con">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649606/Vyorius%20Website/page5img1_inq0ig.png" alt=" " />
            <h5> Internet of robotic things </h5>{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6">
            <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649606/Vyorius%20Website/page5img2_m7nmhw.png" alt=" " />
            <h5> AI supported operations optimization </h5>{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6">
            <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649607/Vyorius%20Website/page5img3_zk3k3f.png" alt=" " style={{ width: "200px" }} />
            <h5> Integrated workflows </h5>{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6">
            <img src="https://res.cloudinary.com/vyorius-drones-llp/image/upload/v1625649608/Vyorius%20Website/page5img4_ilstyi.png" alt=" " style={{ width: "200px" }} />
            <h5> Predictive maintenance </h5>{" "}
          </div>{" "}
        </div>{" "}
      </div> */}
    </div>
  );
}
export default Page5;
