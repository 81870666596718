import React from 'react';
import Page3 from './page3';
import Page4 from './page4';
import Page5 from './page5';

function WhyVyorius() {
  return (
    <>
      <Page3 />
      <Page4 />
      <Page5 />
    </>
  );
}
export default WhyVyorius;
